.mobile-view {
  background-image: url(../../../svgIcons/dashboard/dashboard_bg_2.svg),
    url(../../../svgIcons/dashboard/dashboard_bg_1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top, left bottom -300px;
  background-color: #16243d;
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  padding: 20px;
  width: 100%;
}

.mobile-view_container {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  width: 355px;
}

.mobile-view_card {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}

.mobile-view_text{
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
}
.mobile-view_download {
  border-radius: 10px;
  border: 1px solid #e8e9eb;
  background: #fafafa;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.download-button {
  border-radius: 5px;
  background: #e73a6e;
  display: flex;
  width: 250px;
  padding: 10px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download-button-arrow {
  width: 14.849px;
  height: 14.849px;
  transform: rotate(45deg);
  border-radius: 3px;
  background: #e73a6e;
  position: absolute;
  top: 80%;
}

.download-button_store {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


@media screen and (max-width: 480px) {

  
    .mobile-view_text h1 {
      font-size: 16px;
    }
  
    .mobile-view_text p {
      font-size: 12px;
      width: 100%;
    }
  
    .download-button_store {
      flex-direction: column;
      align-items: center;
    }
  
    .download-button_store img {
      width: 90px;
    }
  }